import React from 'react';
import Layout from '../components/Layout';
import styled from 'styled-components';
import PostItem from '../components/PostItem';
import MediaQueries from '../utils/media-queries';
import SEO from '../components/SEO/SEO';
import { Link, graphql } from 'gatsby';
import Img from 'gatsby-image';

const PostContainer = styled.div`
  display: grid;
  grid-template-areas: 'projects';
  grid-template-columns: 1fr;
  margin-top: 6vh;
  padding: 0 1rem;
  grid-gap: 2rem;

  section {
    padding: 1rem;
    margin-bottom: 3rem;
  }
  @media only screen and (max-width: ${MediaQueries.fish}) {
    padding: 0 0.2rem;
    section {
      padding: 1rem 0 0;
      h3 {
        margin-left: 1rem;
      }
    }
  }
  /* @media only screen and (max-width: ${MediaQueries.insect}) {
    margin-top: 1rem;
    grid-template-areas:
      'projects';
    grid-template-columns: 1fr;
    grid-template-rows: min-content min-content;
    grid-gap: 0.2rem;
    padding: 0;
  } */
`;

const ReelTitle = styled.h3`
  @media only screen and (max-width: ${MediaQueries.fish}) {
    display: none;
  }
`;

const ShowReel = styled.div`
  margin-bottom: 2rem;
  border: 1px solid gold;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: center;
  @media only screen and (max-width: ${MediaQueries.fish}) {
    display: none;
  }
  a {
    opacity: 0.4;
    transition: 0.5s;
    :hover {
      opacity: 1;
      flex-grow: 2 !important;
    }
    div {
      img {
      }
    }
  }
`;

const IndexPage = ({ data }) => {
  const SortProjectPosts = (a, b) => {
    if (a.node.frontmatter.priority < b.node.frontmatter.priority) {
      return -1;
    }
    if (a.node.frontmatter.priority > b.node.frontmatter.priority) {
      return 1;
    }
    return 0;
  };

  const SortedProjects = data.Posts.edges.sort(SortProjectPosts);

  const ProjectPosts = SortedProjects.map(({ node: post }) =>
    post.frontmatter.posttype === 'Project' ? (
      <PostItem key={post.id} post={post} type={post.frontmatter.posttype} />
    ) : null
  );

  const Portfolio = data.Portfolio.edges.map(({ node: portfolio }) =>
    portfolio.frontmatter.posttype === 'Project' ? (
      <Link style={{ height: '100%', flexGrow: '1' }} key={portfolio.id} to={portfolio.fields.slug}>
        <Img
          title={portfolio.frontmatter.title}
          fluid={portfolio.frontmatter.coverimage.childImageSharp.fluid}
          alt={portfolio.frontmatter.title}
          style={{ height: '150px' }}
        />
      </Link>
    ) : null
  );

  return (
    <Layout>
      <SEO homepage />
      <PostContainer>
        <section>
          <ReelTitle
            style={{
              textDecoration: 'overline',
              textDecorationColor: 'gold',
              marginBottom: '1rem',
            }}
            id='reel'
          >
            Reel
          </ReelTitle>
          <ShowReel>{Portfolio}</ShowReel>

          <h3
            style={{
              textDecoration: 'overline',
              textDecorationColor: 'gold',
            }}
            id='projects'
          >
            Projects
          </h3>
          {ProjectPosts}
        </section>
      </PostContainer>
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query {
    Posts: allMarkdownRemark {
      edges {
        node {
          id
          excerpt(pruneLength: 75)
          timeToRead
          frontmatter {
            date(formatString: "DD MMMM, YYYY")
            unformatteddate: date
            title
            posttype
            description
            priority
            coverimage {
              childImageSharp {
                fluid(maxWidth: 1200) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
    Portfolio: allMarkdownRemark(
      filter: { frontmatter: { posttype: { ne: "Blog" } } }
      sort: { fields: [frontmatter___priority], order: ASC }
    ) {
      edges {
        node {
          fields {
            slug
          }
          id
          frontmatter {
            title
            date(formatString: "DD MMMM, YYYY")
            posttype
            coverimage {
              childImageSharp {
                fluid(maxWidth: 400) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
        }
      }
    }
  }
`;
