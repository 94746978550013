import React from 'react';
import Img from 'gatsby-image/withIEPolyfill';
import styled from 'styled-components';
import MediaQueries from '../utils/media-queries';
import { Link } from 'gatsby';

const PostItemContainer = styled.article`
  border-bottom: ${(props) =>
    props.type === 'Project' ? '1px solid #FFD700' : '1px solid #333333'};
  padding: ${(props) => (props.type === 'Project' ? '1rem 0 1rem' : '1rem 0')};
  transition: 0.3s;
  box-shadow: ${(props) =>
    props.type === 'Project' ? '10px 0px 20px -20px #ccc' : '-10px 0px 20px -20px #ccc'};
  :hover {
    //background-color: #fcfbfc;
  }
  a {
    color: inherit;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    :visited {
      color: inherit;
    }
  }
  h4 {
    padding: 0 1rem;
  }
  @media only screen and (max-width: ${MediaQueries.fish}) {
  }
  @media only screen and (max-width: ${MediaQueries.insect}) {
  }
`;

/* const BlogTime = styled.time`
  display: block;
  font-size: 0.8rem;
  margin: 0 1rem 0.2rem 0;
  text-align: right;
`; */
/* const TimeToRead = styled.span`
  display: block;
  font-size: 0.8rem;
  margin: 0 1rem 1rem 0;
  text-align: right;
`; */

const More = styled.p`
  width: min-content;
  display: block;
  white-space: nowrap; // stop text from wrapping on mobile - still needs testing
  margin: 1rem 0.2rem 0 1rem;
  padding: 0.5rem 1rem;
  ${(props) => (props.type === 'Project' ? 'background-color: gold; border-radius: 5px;' : null)}
  text-decoration: underline !important;
  text-transform: lowercase;
  font-size: 0.8rem;
  transition: padding 0.3s cubic-bezier(0, 1.5, 0.3, 1.5);
  :hover {
    padding: 0.5rem 2rem;
  }
`;

const ItemGrid = styled.div`
  display: grid;
  grid-template-columns: 2fr 1rem 3fr;
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    'image . projtitle'
    'image . projdesc'
    'image . cta';
  @media only screen and (max-width: ${MediaQueries.insect}) {
    grid-template-columns: 1fr;
    grid-template-rows: auto 1rem auto auto auto;
    grid-template-areas:
      'image'
      '.'
      'projtitle'
      'projdesc'
      'cta';
  }
`;

const RenderProjectItem = (post) => {
  return (
    <PostItemContainer type={post.frontmatter.posttype}>
      <Link to={post.fields.slug}>
        <ItemGrid>
          <Img
            fluid={post.frontmatter.coverimage.childImageSharp.fluid}
            alt={`Cover image for ${post.frontmatter.title}`}
            objectFit='cover'
            objectPosition='50% 50%'
            style={{
              height: '30vh',
              gridArea: 'image',
            }}
          />
          <h4 style={{ padding: '0', gridArea: 'projtitle' }}>{post.frontmatter.title}</h4>
          <p style={{ margin: 0, padding: '0 1rem', gridArea: 'projdesc' }}>
            {post.frontmatter.description || post.excerpt}
          </p>
          <More type={post.frontmatter.posttype} style={{ gridArea: 'cta' }}>
            Find out more...
          </More>
        </ItemGrid>
      </Link>
    </PostItemContainer>
  );
};

/* const RenderBlogItem = post => {
  return (
    <PostItemContainer>
      <Link to={post.fields.slug}>
        <BlogTime>Posted: {post.frontmatter.date}</BlogTime>
        {post.frontmatter.updated !== 'Invalid date' ? (
          <BlogTime>Last Updated: {post.frontmatter.updated}</BlogTime>
        ) : null}
        <TimeToRead>
          {post.timeToRead} {post.timeToRead > 1 ? 'minutes' : 'minute'} to read
        </TimeToRead>
        <h4 style={{ padding: '0' }}>{post.frontmatter.title}</h4>
        <p style={{ margin: 0, padding: '0 1rem' }}>
          {post.frontmatter.description || post.excerpt}
        </p>
        <More>Read more...</More>
      </Link>
    </PostItemContainer>
  );
}; */

export default (props) => {
  return props.type === 'Project' ? (
    RenderProjectItem(props.post)
  ) : props.type === 'Blog' ? /* RenderBlogItem(props.post) */ null : (
    <div style={{ background: 'tomato', color: 'white', fontSize: '1.1rem' }}>
      Error: Unknown Post Type
    </div>
  );
};
